import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import "./NavBar.scss";
import CustomSwitch from "../Switch/Switch"; // Renamed Switch to CustomSwitch
import MobileSwitch from "../Switch/MobileSwitch/MobileSwitch";
import Logo from "../Logo/Logo";

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const options = ["Pickett", "About", "Donate"];
    const pathName = location.pathname.split("/")[1];
    let index = 0;
    switch (pathName) {
        case "":
            index = 0;
            break;
        case "about":
            index = 1;
            break;
        case "line":
            index = 2;
            break;
        default:
            index = 0;
            break;
    }
    
    const { user, isAuthenticating, logout } = useAuth();
    

    const [toggleMobile, setToggleMobile] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    
    const handleTabChange = (index) => {
        console.log("Changed Tab");
        setToggleMobile(false);
        if(index === 0){
            navigate('/');
        } else if(index === 1){
            navigate('/about');
        } else if(index === 2){
            navigate('/line/BDS Official Boycott');
        }
    };

    return (
        <div className="nav-bar">
            <div className="nav-left">
                {width < 500 ?
                    <div onClick={()=>{setToggleMobile(!toggleMobile)}}>
                        <Logo size={25}  />
                    </div>
                    : 
                    <a onClick={()=>{navigate('/')}}><Logo size={25} /></a>
                }
                <div className="break" />
                <div className="nav-bar-container">
                    {width < 500 ? 
                    <MobileSwitch
                        options={options}
                        onChange={handleTabChange}
                        toggle={toggleMobile}
                        setToggle={setToggleMobile}
                        initialSelected={index}
                    />
                    :
                    <CustomSwitch
                        options={options}
                        onChange={handleTabChange}
                        toggle={toggleMobile}
                        setToggle={setToggleMobile}
                        mobile={width < 500}
                        initialSelected={index}
                    />
                    }
                </div>
            </div>
            {
                !isAuthenticating && user ? 
                <div className="nav-right">
                    <div className="profile-name" onClick={()=>{navigate(`/profile/${user.username}`)}}>
                        {user.username}
                    </div>
                    <div className="pfp">
                        <img src={user.pfp} alt="" />
                    </div>
                    <div className="join-button" onClick={logout}> logout</div>
                </div>

                : 
                <div className="nav-right">
                    <div className="login-button" onClick={()=>{navigate("/login")}}> login </div>
                    <div className="join-button" onClick={()=>{navigate("/login")}}> join</div>
                </div>
            }
        </div>
    );
};

export default NavBar;
