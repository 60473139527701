import React, { useState, useMemo, useRef, useEffect } from 'react';
import './DailyWaffleWidget.scss';

const DailyWaffleWidget = ({ 
  values = [], 
  thresholds = [5, 30, 50, 70, 100], 
  colors = ["#EDEDED", "#F2C4C1", "#F29C97", "#F1746D", "#EB675F"], 
  toolTip = "people held the line on",
  initialDate = new Date(), 
  startIndex = 0, 
  endIndex = -1,
  maxWidth = 310, 
  maxHeight = 310, 
  gap = 2
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [tooltipStyle, setTooltipStyle] = useState({});
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const { boxSize, boxesPerRow, totalRows, displayValues } = useMemo(() => {
    if (!Array.isArray(values) || values.length === 0) {
      return { boxSize: 0, boxesPerRow: 0, totalRows: 0, displayValues: [] };
    }

    const end = endIndex === -1 ? values.length : Math.min(endIndex, values.length);
    const displayValues = values.slice(startIndex, end);
    const totalBoxes = displayValues.length;
    const aspectRatio = maxWidth / maxHeight;
    
    let boxesPerRow = Math.ceil(Math.sqrt(totalBoxes * aspectRatio));
    let rows = Math.ceil(totalBoxes / boxesPerRow);
    
    let boxWidth = (maxWidth - (boxesPerRow - 1) * gap) / boxesPerRow;
    let boxHeight = (maxHeight - (rows - 1) * gap) / rows;
    
    let boxSize = Math.floor(Math.min(boxWidth, boxHeight));

    boxesPerRow = Math.floor((maxWidth + gap) / (boxSize + gap));
    rows = Math.ceil(totalBoxes / boxesPerRow);

    if (rows * (boxSize + gap) - gap > maxHeight) {
      boxSize = Math.floor((maxHeight + gap) / rows - gap);
    }

    return { boxSize, boxesPerRow, totalRows: rows, displayValues };
  }, [values, startIndex, endIndex, maxWidth, maxHeight, gap]);

  const borderRadius = useMemo(() => Math.max(2, boxSize * 0.2), [boxSize]);

  const getColor = (value) => {
    for (let i = 0; i < thresholds.length; i++) {
      if (value <= thresholds[i]) return colors[i];
    }
    return colors[colors.length - 1];
  };

  const getDateForIndex = (index) => {
    const date = new Date(initialDate);
    date.setDate(date.getDate() + index);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  };

  const handleMouseEnter = (index, event) => {
    const rect = event.target.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    
    setHoveredIndex(index);
    
    setTimeout(() => {
      if (tooltipRef.current) {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        let left = rect.left - containerRect.left + boxSize / 2;
        
        if (left + tooltipRect.width / 2 > containerRect.width) {
          left = containerRect.width - tooltipRect.width;
        }
        else if (left - tooltipRect.width / 2 < 0) {
          left = 0;
        }

        setTooltipStyle({
          left: `${left}px`,
          top: `${rect.bottom - containerRect.top + 5}px`,
          transform: left === 0 ? 'none' : (left === containerRect.width - tooltipRect.width ? 'none' : 'translateX(-50%)'),
        });
      }
    }, 0);
  };

  const gridWidth = Math.min(maxWidth, boxesPerRow * boxSize + (boxesPerRow - 1) * gap);
  const gridHeight = Math.min(maxHeight, totalRows * boxSize + (totalRows - 1) * gap);
  
  useEffect(()=>{
    console.log(values.length);
  },[values])
  if (displayValues.length === 0) {
    return "";
  }


  return (
    <div 
      ref={containerRef}
      style={{ 
        position: 'relative',
        width: `${gridWidth}px`,
        height: `${gridHeight}px`,
        marginRight: '20px',
      }}
    >
      {displayValues.map((value, index) => {
        const column = index % boxesPerRow;
        const centerCol = boxesPerRow / 2;
        return (
          <div
            key={index + startIndex}
            style={{
              position: 'absolute',
              left: `${column * (boxSize + gap)}px`,
              top: `${Math.floor(index / boxesPerRow) * (boxSize + gap)}px`,
              width: `${boxSize}px`,
              height: `${boxSize}px`,
            }}
          >
            <div
              className='animated-waffle-effect'
              style={{
                width: '100%',
                height: '100%',
                borderRadius: `${borderRadius}px`,
                backgroundColor: getColor(value),
                transition: 'background-color 0.5s ease, border-radius 0.5s ease',
                animationDelay: `${Math.abs(column-centerCol) * 0.07}s`,
              }}
              onMouseEnter={(e) => handleMouseEnter(index + startIndex, e)}
              onMouseLeave={() => setHoveredIndex(null)}
            />
          </div>
        );
      })}
      {hoveredIndex !== null && (
        <div
          ref={tooltipRef}
          style={{
            position: 'absolute',
            ...tooltipStyle,
            backgroundColor: 'black',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            zIndex: 1000,
            pointerEvents: 'none',
          }}
        >
          {values[hoveredIndex]} {toolTip} {getDateForIndex(hoveredIndex)}
        </div>
      )}
    </div>
  );
};

export default DailyWaffleWidget;