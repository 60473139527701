import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as GroupsIcon } from "../../../resources/participantsIcon.svg";
import "./LinePreviewer.scss";

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength).trim() + "...";
  } else {
    return text;
  }
}

function LinePreviewer({
  title,
  coverPic,
  bio,
  owner,
  ownerPFP,
  participants,
  site,
  onChange,
}) {
  const [currentTitle, setCurrentTitle] = useState("");
  const [currentCoverPic, setCurrentCoverPic] = useState("");
  const [currentBio, setCurrentBio] = useState("");
  const [currentOwner, setCurrentOwner] = useState("");
  //   this prob shouldn't be a string
  const [currentParticipants, setCurrentParticipants] = useState("");
  const [currentsite, setCurrentsite] = useState("");
  const [currentOwnerPFP, setCurrentOwnerPFP] = useState("");

  const bioRef = useRef(null);
  useEffect(() => {
    setCurrentTitle(title);
    setCurrentCoverPic(coverPic);
    if(bio.length > 540){
        setCurrentBio(bio);
        } else {
            setCurrentBio(bio);
        }
    setCurrentOwner(owner);
    setCurrentParticipants(participants);
    setCurrentsite(site);
    setCurrentOwnerPFP(ownerPFP);
  }, [title, coverPic, bio, owner, ownerPFP, participants, site]);

  const isOverflown = (element) => {
    if (element.scrollHeight > element.clientHeight) {
      return true;
    }
    return false;
    };

  return (
    
    <div className="line-previewer sad">
      <span className={`heading-line`}>
        {<h2 className="preview-title">{currentTitle}</h2>}
        <span className={`line-participant-count`}>
          <GroupsIcon className="participants-icon" />
          {currentParticipants}
        </span>
      </span>
      <img
        className="line-thumb-nail"
        src={currentCoverPic}
        alt={`${currentTitle} cover image`}
      />
      <p className="preview-bio" ref={bioRef}>{currentBio}</p>
    {/* replace image with actually pfp also change  line-owner-tag to a link to the owner's profile */}
      <div className="">
        <span className={`line-owner-tag`}>
          <div className="line-owner-pfp-container">
            <img
            className="line-owner-pfp-home"
            src={currentOwnerPFP}
            alt={`${currentOwner} profile picture`}
            />
          </div>
          {currentOwner}
        </span>
      </div>
    </div>
  );
}

export default LinePreviewer;
