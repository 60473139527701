import React, { useState, useEffect } from "react";
import "./Redirect.scss";
import { useLocation } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { useData } from "../../contexts/DataContext";
import PickettList from "../../components/PickettList/PickettList";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";

function Redirect() {
  const { fetchData } = useData();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get("data");
  const decodedData = decodeURIComponent(data);
  const parsedData = JSON.parse(decodedData);
  const [picketts, setPicketts] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    fetchData("/api/picketts").then((data) => {
      setPicketts(data.data);
    });
  }, []);

  useEffect(() => {
    //useEffect for window resizing
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [viewport, setViewport] = useState("100vh");
  useEffect(() => {
    setViewport(window.innerHeight + "px");
  }, []);

  return (
    <div
      className="redirect"
      style={{ height: width < 800 ? viewport : "100vh" }}>
      <div className="redirect-content">
        <div className="header">
          <Logo size={18} />
          <p>pickett</p>
        </div>
        <div className="boycott-alert">
          <p>
            looks like you’re trying to access a boycotted website.{" "}
            <span className="highlight">{parsedData.site}</span> is included in
            the following pickett lines:
          </p>
          <div className="secondary-text"></div>
        </div>
        <PickettList picketts={picketts} />
      </div>
      <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
    </div>
  );
}

export default Redirect;
