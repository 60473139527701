import React, { useState, useEffect } from "react";
import "./LineSelector.scss";
import { ReactComponent as GroupsIcon } from "../../resources/participantsIcon.svg";
import { useNavigate } from "react-router-dom";
import LinePreviewer from "./linePreview/LinePreviewer";
import { useData } from "../../contexts/DataContext";
// we need to put limits on user pfp sizes or things will get fucked, maybe a cropping tool at some point could fix

const LineSelector = ({ previewInfo = {}, setPreviewInfo = {}, hasJoinButton = true }) => {
  const [urgentLines, setUrgentLines] = useState([]);
  const [anySelected, setAnySelected] = useState(false);
  const [lastHovered, setLastHovered] = useState(1);
  const [fadeIn, setFadeIn] = useState(true);
  const [fadingOut, setFadingOut] = useState(false);
  const [hovered, setHovered] = useState(1);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const { fetchData } = useData();

  const pickettsDisplay = window.innerWidth < 500 ? 4 : 10;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPicketts = async () => {
      const fetchedLines = await fetchData("/api/picketts", "get", null, {
        params: { number: pickettsDisplay },
      });  
      console.log(fetchedLines);
      const linesWithSelection = fetchedLines.data.map((line) => ({
        ...line,
        selected: false,
      }));
      setUrgentLines(linesWithSelection);
    }; 
    fetchPicketts();
  }, []);

  useEffect(() => {
    setAnySelected(urgentLines.some((line) => line.selected));
    // ERROR: Data not passed out correctly.
    setPreviewInfo({urgentLines: urgentLines.filter((line) => line.selected)});
  }, [urgentLines]);

  useEffect(() => {
    if (fadingOut) {
      const timeout = setTimeout(() => {
        setFadingOut(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [fadingOut]);

  const handleCheckBoxClick = (id) => {
    setUrgentLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === id) {
          const newParticipants = line.selected
            ? line.participants - 1
            : line.participants + 1;
          return {
            ...line,
            selected: !line.selected,
            participants: newParticipants,
          };
        }
        return line;
      })
    );
  };

  const handleContentBoxClick = (id) => {
    setUrgentLines((prevLines) =>
      prevLines.map((line) => {
        if (line.id === id && line.selected) {
          return {
            ...line,
            selected: false,
            participants: line.participants - 1,
          };
        } else if (line.id === id && !line.selected) {
          navigate(`/line/${line.name}`);
        }
        return line;
      })
    );
  };

  const handleButtonClick = () => {
    const joinedLines = urgentLines
      .filter((line) => line.selected)
      .map((line) => line.name);
    alert(`You have joined the following lines:\n${joinedLines.join("\n")}`);
  };

  const handleContentBoxMouseEnter = (id) => {
    setHovered(id);

    clearTimeout(hoverTimeout);
    if (lastHovered != id) {
      setFadingOut(true);
      setFadeIn(false);
    }
    const timeout = setTimeout(() => {
      setTimeout(() => {
        setLastHovered(id);
        setFadeIn(true);
      }, 100);
    }, 100);

    setHoverTimeout(timeout);
  };

  const handleContentBoxMouseLeave = () => {
    // if (hoverTimeout < 200) {
    //   clearTimeout(hoverTimeout);
    // }
  };

  const lastHoveredLine = urgentLines.find((line) => line.id === lastHovered);


  // ERROR: Need to space these better
  return (
    <div className="line-viewer-connecter">
      <div className="line-selector-container">
        <div className="line-selector-ui">
          <div className="urgent-lines-container">
            <div className="label-grid">
              <p className="label-first">join</p>
              <p className="label-second">pickett</p>
              <p className="label-third">participants</p>
            </div>
            {urgentLines.map((line) => (
              <div
                key={line.id}
                className={`urgent-line ${line.selected ? "selected" : ""}`}
              >
                <div
                  className={`check-box ${line.selected ? "hide" : ""}`}
                  onClick={() => handleCheckBoxClick(line.id)}
                ></div>
                <div
                  className="content-box"
                  onClick={
                    window.innerWidth < 768
                      ? line.selected
                        ? () => handleContentBoxClick(line.id)
                        : () => handleContentBoxMouseEnter(line.id)
                      : () => handleContentBoxClick(line.id)
                  }
                  onMouseEnter={() => handleContentBoxMouseEnter(line.id)}
                  onMouseLeave={handleContentBoxMouseLeave}
                >
                  <span
                    className={`line-name ${
                      line.id === hovered ? "red-text" : ""
                    }`}
                  >
                    {line.name}
                  </span>
                  <span
                    className={`line-participants line-participants-${
                      line.id
                    } ${line.id === hovered ? "red-text" : ""}`}
                  >
                    <GroupsIcon
                      className={`participants-icon ${
                        line.id === hovered ? "red-icon" : ""
                      }`}
                    />
                    {line.participants.toLocaleString()}
                  </span>
                </div>
              </div>
            ))}
            {hasJoinButton && (
              <div className="join-button-container">
                <div
                  className={`join-selected-button ${
                    anySelected ? "" : "no-selection"
                  }`}
                  onClick={handleButtonClick}
                >
                  Join the Pickett
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {lastHoveredLine && (
        <div className={`last-hovered ${fadeIn ? "visible" : ""}`}>
          <LinePreviewer
            className="home-page-previewer"
            title={lastHoveredLine.title}
            coverPic={lastHoveredLine.coverPic}
            bio={lastHoveredLine.bio}
            organizer={lastHoveredLine.organizer}
            organizerPFP={lastHoveredLine.organizerPFP}
            participants={lastHoveredLine.participants}
            site={lastHoveredLine.site}
            onChange={() => handleContentBoxMouseEnter(lastHoveredLine.id)}
          />
        </div>
      )}
    </div>
  );
};

export default LineSelector;
