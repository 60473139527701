import React, { useState, useEffect } from "react";
import "./Line.scss";
import { useData } from "../../contexts/DataContext";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import NavBar from "../../components/NavBar/NavBar";
import RelatedLineViewer from "../../components/LineViewer/LineViewer";
import ScrollingBanner from "../../components/ScrollBanner/ScrollBanner";
import LineStatDisplay from "../../components/LineStatDisplay/LineStatDisplay";
import LineBioRenderer from "../../components/HtmlCssRenderer/HtmlCssRenderer";
import IncludedLinesList from "../../components/UrlDescriptionBoxes/UrlDescriptionBoxes";
import Label from "../../components/Label/Label";
import Switch from "../../components/Switch/Switch";
import ErrorRedirect from "../ErrorRedirect/ErrorRedirect.jsx";

import tempPFPLineOwner from "../../resources/highResPFPTest.jpg";
import exampleLineCover from "../../resources/testLineCover.jpg";
import {useFetch} from '../../hooks/useFetch.js';

function Line() {
  const name = useParams().name;
  const [picketts, setPicketts] = useState([]);
  const [line, setLine] = useState(null);
  const [owner, setOwner] = useState(null);

  const navigate = useNavigate();

  const lineData = useFetch(`/api/get-line/${name}`);
  const pickettsData = useFetch('/api/picketts');

  useEffect(()=>{
    const {data, loading, error} = lineData;
    if(loading){
        return;
    }
    if(error){
        console.log(error);
        return;
    }
    if(data){
        console.log(data);
        setLine(data.data);
        setOwner(data.data.organizer);
        if(data.following){
            
        }
    }

  }, [lineData]);

  useEffect(()=>{
    if(pickettsData.loading || pickettsData.error){
        return;
    }
    setPicketts(pickettsData.data.data);
  },[pickettsData])

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const [showBio, setShowBio] = useState(true); //for toggling between bio and sites
    const handleBioSwitch = (index) => {
      setShowBio(index === 0);
    }

    if(lineData.loading){
        return (
            <div className="line">
            </div>
        );
    }

  return (
    <>
        {!lineData.loading && !lineData.error && 
        <div className="line page">
            <>
                <ScrollingBanner items={picketts.map((pickett) => pickett.name)} />
                <div className="line-page-contents">
                    <span className="title-cover-alignment">
                    <span className="title-card">
                    <h1 className="line-title">{name}</h1>
                    {owner && (
                    <span className={`line-owner-tag`}
                        onClick={()=>{navigate(`/profile/${owner.username}`)}}
                    >

                        <div className="line-owner-pfp-container">
                            
                            <img
                            className="line-owner-pfp-home"
                            src={owner.pfp}
                            alt={`${owner.username} profile picture`}
                            />
            
                        </div>
                        {owner.username}
                    
                        </span>
                    )}
                    </span>
                    <span className="cover-and-stat-alignment">
                        <img className="line-cover" src={tempPFPLineOwner}></img>
                    { line && ( <LineStatDisplay stats={{
                        grid: [
                            60, 74, 70, 64, 68, 74, 60, 78, 79, 76, 74, 77, 83, 72, 83, 73, 85, 74, 64,
                            74, 78, 70, 79, 79, 66, 82, 86, 84, 86, 75, 75, 64, 90, 82, 85, 73, 73, 69,
                            75, 85, 88, 91, 80, 92, 80, 100, 79, 87, 71, 79, 78, 80, 85, 75, 89, 84, 92,
                            83, 85, 86, 91, 82, 86, 100, 96, 95, 86, 96, 76, 81, 87, 82, 100, 79, 81,
                            97, 90, 77, 83, 88, 92, 89, 100, 89, 95, 99, 96, 97, 94, 98
                        ],
                        thresholds: [60, 70, 80, 90, 100],
                        colors: ["#EDEDED", "#F2C4C1", "#F29C97", "#F1746D", "#EB675F"],
                        initialDate: "2023-08-20"
                    }} participants={1000} target={2000} id={line && line._id} following={lineData.data.following}/> )}
                    </span>
                    {
                        width <= 500 &&
                        <div className="bio-switch-container">
                            <Switch options={["Bio", "Included Sites"]} onChange={handleBioSwitch} mFSize={15}/>
                        </div>
                        
                    }
                    <div className={`line-bio-and-included-sites ${showBio ? "" : "included-sites"}`}>
                        <div className="line-bio-wrapper">
                        {/* ERROR: This is potentially risky for js injections I think, we can heavily modify the text since we're making it with them */}
                        {/* Other potential Issue is this adds alot of potential work on the line creation, might swap this for plain text for launch till v2 */}
                        {/* UPDATE: Risk should be mitigated since we swapped html renderer */}
                        {line && (
                        <LineBioRenderer 
                        htmlContent={line.bio}
                        cssContent={""}
                        />
                        )}
                        {/* i think this should toggle between 20 vs 15px margin-top but it would make  */}
                        {/* the code so much less clean... */}
                        <div className="related-lines-container">
                            <RelatedLineViewer title="related lines" lines={[]} />
                        </div>
                        </div>
                        <div className="included-lines-wrapper">
                        
                        <Label className="included-lines-label" label={`${width <= 500 ? "" : "included-sites"}`} >
                        {line && ( 
                            <IncludedLinesList urls={[]} descriptions={[]} />
                        )}
                            </Label>
                        </div>
                        
                    </div>
                </span>
                </div>
            </>

        </div>
        }
        {
            lineData.error && <ErrorRedirect/>
        }
    </>
  );
}

export default Line;
