import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom'; //allows for nested routes to be rendered within this layout
import NavBar from '../../components/NavBar/NavBar';
import './Layout.scss';

function Layout() {
  const noNav = ['/login', '/register', '/create-line', '/redirect'];
    const location = useLocation();
  const [showNav, setShowNav] = useState(true);

    useEffect(() => {
        if(noNav.includes(window.location.pathname)){
            setShowNav(false);
        } else {
            setShowNav(true);
        }
    }
    , [location]);  

  const [viewport, setViewport] = useState("100vh");
  useEffect(() => {
      let height = window.innerHeight;
      setViewport(height + 'px');
      //add listener
  },[]);
  
  return (
    <div style={{height:viewport}}>
      <main style={{height:viewport}}>
        <div className="out" style={{height:viewport}} >
          {showNav && <NavBar />}
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default Layout;